import { useMutation } from '@apollo/client';
import { Button, Card, Col, Descriptions, Row, Switch } from 'antd';
import { includes, nth } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { Active, EditButton, Inactive } from '../../../../../assets/svg';
import {
  ACCESSTYPE_KEYS,
  ADDONS,
  BREAKPOINTS,
  ORGANIZATION_TYPES,
  ROUTES,
  defaultDateFormat,
} from '../../../../../common/constants';
import Portal from '../../../../../components/Portal';
import useRouter from '../../../../../hooks/useRouter';
import DeleteModal from '../../../DeleteModal';
import { UPDATE_ORGANIZATION } from '../../../graphql/Mutations';
import { GET_ORGANIZATIONS } from '../../../graphql/Queries';

const Details = ({ data, fetchOrganizationData, pageFilter }) => {
  const fetchData = nth(data?.tenantList?.data, 0);
  const { navigate } = useRouter();
  const [showModal, setShowModal] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [updateTenant] = useMutation(UPDATE_ORGANIZATION, {
    onError() { },
    refetchQueries: [
      {
        query: GET_ORGANIZATIONS,
        variables: {
          filter: { sortBy: { field: 'updatedAt', order: 'DESC' } },
        },
      },
    ],
  });

  return (
    <div>
      <Portal portalId="header-right-content">
        {showModal && (
          <DeleteModal
            showModal={showModal}
            setShowModal={setShowModal}
            title={fetchData?.isDisabled ? `Activate` : `Deactivate`}
            updateTenant={updateTenant}
            mutationId={fetchData?.id}
            isDisabled={fetchData?.isDisabled}
            fetchOrganizationData={fetchOrganizationData}
            name="Organization"
            subtitle="Organization"
          />
        )}
        <div className="action d-flex">
          <Button
            type={isDesktopViewport ? 'primary' : 'text'}
            shape={isDesktopViewport ? 'round' : 'circle'}
            className="mr-5"
            onClick={() => {
              setShowModal(true);
            }}
          >
            {/* eslint-disable no-nested-ternary */}
            {isDesktopViewport ? (
              fetchData?.isDisabled ? (
                `Activate`
              ) : (
                `Deactivate`
              )
            ) : fetchData?.isDisabled ? (
              <Active />
            ) : (
              <Inactive />
            )}
          </Button>
          <Button
            type={isDesktopViewport ? 'primary' : 'text'}
            shape={isDesktopViewport ? 'round' : 'circle'}
            icon={!isDesktopViewport && <EditButton />}
            onClick={() => {
              navigate(`${ROUTES.ORGANIZATION}/edit/${fetchData?.id}`, {
                state: { pageFilter },
              });
            }}
          >
            {isDesktopViewport && 'Edit'}
          </Button>
        </div>
      </Portal>
      <Row gutter={24}>
        <Col xs={24} lg={8}>
          <Card className="mb-10">
            <h1>Organization Details</h1>
            <Descriptions
              layout="vertical"
              column={1}
              className="details-owner-description"
            >
              <Descriptions.Item label="Organization Name">
                {fetchData?.organizationName}
              </Descriptions.Item>
              <Descriptions.Item label="System Admin Name">
                {fetchData?.ownerName}
              </Descriptions.Item>
              <Descriptions.Item label="System Admin Email">
                {fetchData?.adminEmail}
              </Descriptions.Item>
              <Descriptions.Item label="System Admin Mobile Number">
                {fetchData?.phoneNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Organization Type">
                {ORGANIZATION_TYPES[fetchData?.organizationType] || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="TimeZone">
                {fetchData?.timeZone || '-'}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card className="mb-10">
            <h1>Subscription Details</h1>
            <Descriptions
              layout="vertical"
              column={1}
              className="details-owner-description"
            >
              <Descriptions.Item label="Current Plan">
                {fetchData?.planName}
              </Descriptions.Item>
              <Descriptions.Item label="Users">
                {`${fetchData?.userCount}/ ${fetchData?.userLimit}`}
              </Descriptions.Item>
              <Descriptions.Item label="Valid Till">
                {fetchData?.subscription?.validTill
                  ? moment(fetchData?.subscription?.validTill).format(
                    defaultDateFormat,
                  )
                  : '-'}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col xs={24} lg={8}>
          <Card>
            <h1>Addon Services</h1>
            <div className="addon-div">
              <div className="font-weight-500 m-5">1. Access Type</div>
              {includes(fetchData?.access, ACCESSTYPE_KEYS.INSPECTION) && (
                <div className="sub-addon-div">
                  <div>a. Inspection</div>
                  <Switch checked />
                </div>
              )}
              {includes(fetchData?.access, ACCESSTYPE_KEYS.INSTRUCTION) && (
                <div className="sub-addon-div">
                  <div>b. Instruction</div>
                  <Switch checked />
                </div>
              )}
              <div className="font-weight-500 m-5">2. Whatsapp</div>
              {includes(fetchData?.access, ACCESSTYPE_KEYS.INSPECTION) && (
                <div className="sub-addon-div">
                  <div>a. Inspection</div>
                  <Switch
                    checked={includes(fetchData?.addOn, ADDONS.WHATSAPP)}
                  />
                </div>
              )}
              {includes(fetchData?.access, ACCESSTYPE_KEYS.INSTRUCTION) && (
                <div className="sub-addon-div">
                  <div>b. Instruction</div>
                  <Switch
                    checked={includes(
                      fetchData?.addOn,
                      ADDONS.INSTRUCTION_WHATSAPP,
                    )}
                  />
                </div>
              )}
              <div className="font-weight-500 m-5">3. Photo limit</div>
              {includes(fetchData?.access, ACCESSTYPE_KEYS.INSPECTION) && (
                <div className="sub-addon-div">
                  <div>a. Inspection</div>
                  {fetchData?.inspectionAssetLimit || 5}
                </div>
              )}
              {includes(fetchData?.access, ACCESSTYPE_KEYS.INSTRUCTION) && (
                <div className="sub-addon-div">
                  <div>b. Instruction</div>
                  {fetchData?.instructionAssetLimit || 5}
                </div>
              )}
              {includes(fetchData?.access, ACCESSTYPE_KEYS.INSPECTION) && (
                <>
                  <div className="d-flex justify-between mt-5 align-center">
                    <div className="font-weight-500 m-5">4. Approver</div>
                    <Switch
                      checked={includes(
                        fetchData?.addOn,
                        ADDONS.MULTI_LEVEL_APPROVAL,
                      )}
                    />
                  </div>
                  {includes(fetchData?.addOn, ADDONS.MULTI_LEVEL_APPROVAL) && (
                    <div className="sub-addon-div">
                      <div>a. Approver Level</div>
                      {fetchData?.maxApprovalLevel || 5}
                    </div>
                  )}
                  <div className="d-flex justify-between mt-5 align-center">
                    <div className="font-weight-500 m-5">5. Checklist</div>
                  </div>
                  <div className="sub-addon-div">
                    <div>a. Stage Limit</div>
                    {fetchData?.featureConfig?.stageMaxLimit || 5}
                  </div>
                  <div className="d-flex justify-between mt-5 align-center">
                    <div className="font-weight-500 m-5">
                      6. Gallery access for inspection
                    </div>
                    <Switch
                      checked={includes(
                        fetchData?.addOn,
                        ADDONS.INSPECTION_GALLERY_ACCESS,
                      )}
                    />
                  </div>
                  <div className="d-flex justify-between mt-5 align-center">
                    <div className="font-weight-500 m-5">7. Target</div>
                    <Switch
                      checked={includes(
                        fetchData?.addOn,
                        ADDONS.TARGET_ACTUAL_METRIC,
                      )}
                    />
                  </div>
                  <div className="d-flex justify-between mt-5 align-center">
                    <div className="font-weight-500 m-5">8. Nomenclature</div>
                    <Switch
                      checked={includes(fetchData?.addOn, ADDONS.NOMENCLATURE)}
                    />
                  </div>
                </>
              )}
              {includes(fetchData?.access, ACCESSTYPE_KEYS.INSTRUCTION) && (
                <>
                  <div className="d-flex justify-between mt-5 align-center">
                    <div className="font-weight-500 m-5">
                      9. Instruction Tags
                    </div>
                    <Switch checked={fetchData?.featureConfig?.issueTag} />
                  </div>
                  <div className="d-flex justify-between mt-5 align-center">
                    <div className="font-weight-500 m-5">
                      10. Instruction Recommendation
                    </div>
                    <Switch
                      checked={fetchData?.featureConfig?.issueRecommendation}
                    />
                  </div>
                </>
              )}
              {includes(fetchData?.access, ACCESSTYPE_KEYS.INSPECTION) && (
                <div className="d-flex justify-between mt-5 align-center">
                  <div className="font-weight-500 m-5">11. RFI</div>
                  <Switch checked={includes(fetchData?.addOn, ADDONS.RFI)} />
                </div>
              )}
              <div className="d-flex justify-between mt-5 align-center">
                <div className="font-weight-500 m-5">
                  12. Report Configuration
                </div>
                <Switch
                  checked={includes(fetchData?.addOn, ADDONS.REPORT_CONFIG)}
                />
              </div>
              <div className="d-flex justify-between mt-5 align-center">
                <div className="font-weight-500 m-5">
                  13. Report DigiQC branding
                </div>
                <Switch checked={fetchData?.systemBranding} />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Details;
